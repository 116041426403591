import { navigate } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import Frame from "../components/common/frame"
import Navigation from "../components/navigation"
import "../styles/pages/media-releases-post.scss"

const MediaReleasesPost = ({ pageContext }) => {
  const { mediaRelease } = pageContext
  return (
    <Frame description={mediaRelease?.title} IsFooterVisble="No">
      <header>
        <Navigation
          page="Home"
          dskScroll={400}
          darkHelloBar
          theme="dark"
          darkTheme
          btn1="secondary white"
          btn2="primary white ml-9"
        />
      </header>
      <main className="media-main-container">
        <div className="top-container">
          <div className="top-content">
            <div className={`text-container no-img`}>
              <a onClick={() => navigate("/press-room")} className="text-white">
                <img src="https://media.graphassets.com/C0ldpxRyGZTjqc4sHvlQ" />{" "}
                press room
              </a>
              <h1>{parse(mediaRelease?.titleText?.html)}</h1>
            </div>
          </div>
        </div>
        <div className={`media-content no-img`}>
          {parse(mediaRelease?.descriptionText?.html)}
        </div>
      </main>
    </Frame>
  )
}

export default MediaReleasesPost
